import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/requirejs-handlebars",
  "date": "2013-09-10",
  "title": "REQUIREJS & HANDLEBARS",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "If we are building a web application, code complexity could grow and the app can get bigger. Number of plug-ins can grow bigger, and you will feel the need of adding more modular components. RequireJS can address these issues to increase the speed of your application in an efficient way."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Why RequireJS?`}</h2>
    <p>{`When the application grows, we need a way of loading dependencies via a `}<strong parentName="p">{`#include/import/require`}</strong>{` structure.`}</p>
    <p>{`Also the number of plug-ins can grow bigger, and you will feel the need of adding more modular components. This also could address the ability to load nested dependencies. Uncontrolled scripts can lead in to lose control flow and make it hard to understand the code.`}</p>
    <p><inlineCode parentName="p">{`RequireJS`}</inlineCode>{` is a javascript file and a module loader which can be used to organize your code structure and improve brevity.`}</p>
    <p>{`We can lazy load dependencies as we wish, and reuse components. Using a modular script loader like RequireJs will improve the speed and the quality of your code.`}</p>
    <p>{`RequireJS allows modules to be loaded as fast as possible, even if it is out of order but evaluated in the correct dependency order. With `}<strong parentName="p">{`r.js`}</strong>{`, we could easily minify and combine the app to reduce the amount of script tags.`}</p>
    <p>{`RequireJS is built on the Module Pattern.`}</p>
    <h2>{`The Module Pattern`}</h2>
    <p>{`A javascript code module is a collection of javascript code located in a registered location. All of the code running inside a function lives in a closure, which provides privacy and state throughout the lifetime of the app.`}</p>
    <p>{`Technically, it is an anonymous function that executes immediately.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`(function() {   
  // variables and function definition 
  //  local to this module 
  // access global variables
}());
`}</code></pre>
    <h2>{`1. Adding Require.js to your project`}</h2>
    <p>{`Let's start with downloading the require.js distribution from `}<a parentName="p" {...{
        "href": "http://requirejs.org/docs/download.html"
      }}>{`here`}</a>{`.`}</p>
    <p>{`Once we have the library, let's add it to our project. Insert the following snippet to your index.html or equivalent file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script data-main="app/js/main" src="static/js/lib/require.js"></script>
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`data-main`}</inlineCode>{` attribute tells require.js which file to load (without .js extension treating it a module). This file contains instructions on how
to start up our application. It can also contain the configuration.`}</p>
    <p>{`All other `}{`<`}{`script`}{`>`}{` tags can be removed from your index.html page.`}</p>
    <h2>{`2. Configuring Require.js`}</h2>
    <p>{`Require.js uses AMD style lazy loading, which means that modules need to declare what they depend on, and what they export.`}</p>
    <p>{`Let's create the main.js file now. Insert the project layout description:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`requirejs.config({
  'baseUrl': '/public',
  'paths': {
    'app': 'app/js',
    'jquery': 'js/lib/jquery-2.0.0.min',
    'underscore': 'js/lib/underscore-min-1.5.1',
    'backbone': 'js/lib/backbone-min-1.0.0',
    'handlebars': 'js/lib/handlebars',
  }
});
`}</code></pre>
    <p>{`This assumes the following structure:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`tree .
public/
    app/
        js/
            collections.js            views.js
            models.js
            app.js
            main.js
    js/
        lib/
            jquery-2.0.0.min.js
            underscore-min-1.5.1.js
            backbone-min-1.0.0.js
            handlebars.js</pre>
`}</code></pre>
    <p>{`This informs require.js that `}<inlineCode parentName="p">{`/public `}</inlineCode>{`is the root URL for our app and all paths are from now on relative to this location.`}</p>
    <p>{`And describe where our current dependencies are located. Also notice that we don't specify the `}<inlineCode parentName="p">{`.js`}</inlineCode>{` extension here.  Not all modules can be AMD compliant. Let's look at those next.`}</p>
    <h2>{`3. Non AMD modules and plug-ins`}</h2>
    <p>{`Distributions like `}<inlineCode parentName="p">{`Backbone.js`}</inlineCode>{` and `}<inlineCode parentName="p">{`Underscore.js`}</inlineCode>{` are currently not AMD compliant.`}</p>
    <p><inlineCode parentName="p">{`Shims`}</inlineCode>{` let require.js load non AMD compliant libraries.  Let's add them to our config:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`requirejs.config({
  'baseUrl': '/public',
  'paths': {
    'app': 'app/js',
    'jquery': 'js/vendor/jquery-2.0.0.min',
    'underscore': 'js/lib/underscore-min-1.5.1',
    'backbone': 'js/lib/backbone-min-1.0.0',
    'bootstrap': 'js/lib/bootstrap.min',
    'handlebars': 'js/lib/handlebars',
  },
  'shim': {
    'underscore': {
      'exports': '_'
    },
    'backbone': {
      'deps': ['jquery', 'underscore'],
      'exports': 'Backbone'
    },
    'handlebars': {
      'exports': 'Handlebars'
    }
  }
});
`}</code></pre>
    <p>{`Notice how jQuery does not need such a shim. This is because jQuery version 1.7 and above is AMD compliant, so it should work fine. So now require.js knows what our app looks like. Now it's time to convert our own code to be AMD compliant.`}</p>
    <h2>{`4. Converting our code to be AMD compliant`}</h2>
    <p>{`We'll assume for the purpose of this post that our app is laid out as such:  `}<inlineCode parentName="p">{`views.js`}</inlineCode>{` - containing our Backbone Views, `}<inlineCode parentName="p">{`models.js`}</inlineCode>{` - containing Backbone Models, and `}<inlineCode parentName="p">{`app.js`}</inlineCode>{` - containing our main Application.`}</p>
    <p>{`First, we examine the file and see what dependencies are being used. for example, the `}<inlineCode parentName="p">{`views.js`}</inlineCode>{` file uses jQuery for DOM manipulation, `}<a href="http://handlebarsjs.com/" target="_blank">{`Handlebars`}</a>{` for rendering templates, and Backbone for defining the View subclasses.`}</p>
    <p>{`Now we can wrap our code with a `}<inlineCode parentName="p">{`define()`}</inlineCode>{` function which require.js provides. This function takes 2 arguments: an array of strings (describing the dependencies) and a function that (returns whatever we want to export from this module). This function gets whatever we decided to import as arguments.`}</p>
    <p>{`Let's explain this with an example. Our converted `}<inlineCode parentName="p">{`views.js`}</inlineCode>{` file should now look as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`define(['jquery', 'handlebars',

'backbone'], function($, Handlebars, Backbone) {

  var SomeView = Backbone.view.extend({
    render: function() {
      var template = Handlebars.compile($('#template-someview').html());
      var rendered = template(this.getContext());
      ...
    }
    // more view code
    ...
  });
  ...
  // export stuff:
  return {
    'SomeView': SomeView
  };
});
`}</code></pre>
    <p>{`Require.js knows how to find our jQuery module using the `}<inlineCode parentName="p">{`paths`}</inlineCode>{` declaration we made before in `}<inlineCode parentName="p">{`main.js`}</inlineCode>{`. So loading something such as `}<inlineCode parentName="p">{`jquery`}</inlineCode>{` seems a bit abstract at first.But the module loading configuration is centralized and decoupled from our code. And the views module doesn't need to know where jQuery is located in our project, making it easy to port this module to another project, without changing or adjusting our imports.`}</p>
    <p>{`Also you don't have to return all View subclasses. as with any module, you should only export things that could be used by other modules. It's ok to keep some views or bits of functionality for internal "private" use by the module itself. It's up to you.`}</p>
    <p>{`Repeat this process as necessary for other files in your project. This might also be a good time to break things down into smaller files with smaller scopes of functionality. Don't worry about having to load multiple scripts, we'll use `}<inlineCode parentName="p">{`r.js`}</inlineCode>{` later to concatenate and minify all our resources into a single file later.`}</p>
    <h2>{`5. Launching our application.`}</h2>
    <p>{`Time to fire up our newly converted app. For that, we'll revisit our `}<inlineCode parentName="p">{`main.js`}</inlineCode>{` file, and add the following beneath our `}<inlineCode parentName="p">{`requirejs.config()`}</inlineCode>{` declaration:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`require(['app/app'], function(Application) {
    var app = new Application();
    app.start(); // or whatever startup logic your app uses.
});
`}</code></pre>
    <p>{`This will load your main application and fire it up, the same way a "main" module would probably do in several other languages. Your app is now AMD compliant and uses require.js to manage and declare modules and dependencies. Way to go!`}</p>
    <h2>{`6. Deploying to production`}</h2>
    <p>{`A great tool that require.js comes with `}<a parentName="p" {...{
        "href": "http://requirejs.org/docs/optimization.html"
      }}>{`r.js`}</a>{`. It lets you automatically pull all your dependencies and modules into a single file and minify it. It also lets you do other neat stuff such as concatenating CSS files.`}</p>
    <p>{`The easiest way to build our app for production, is to create a build.js file containing our build configuration. It should look something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`({
  ({
  "baseUrl": "./public",
  "name": "app/main",
  "mainConfigFile": "./public/app/js/main.js",
  "out": "./public/js/app.js"
})
`}</code></pre>
    <p>{`I generally put this file one level above my public folder, since I don't want it to be deployed as a static asset. The most important setting here to note is `}<inlineCode parentName="p">{`mainConfigFile`}</inlineCode>{` - this should be the path to our main module from before.`}</p>
    <p>{`To build the project we need to first install `}<strong parentName="p">{`r.js`}</strong>{`. The easiest way to do that (provided you have `}<a href="http://nodejs.org/" target="_blank">{`node.js`}</a>{`and `}<a href="https://npmjs.org/" target="_blank">{`npm`}</a>{` installed) is to use npm:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install -g requirejs
`}</code></pre>
    <p>{`Once installed, we can call `}<strong parentName="p">{`r.js`}</strong>{` pointing it to our build.js file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`r.js -o build.js
`}</code></pre>
    <p>{`Depending on the size of your project, it could take a few seconds to resolve all dependencies and minify them. Once done, we can change our `}<inlineCode parentName="p">{`script`}</inlineCode>{` tag to include the built version:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<script src="/static/js/vendor/require.js"></script>
<script src="/static/js/app.js"></script>
`}</code></pre>
    <p>{`Notice how we removed the `}<inlineCode parentName="p">{`data-main`}</inlineCode>{` attribute. We added the compiled version of our code instead, containing everything we need inside it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      